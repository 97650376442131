<template>
  <div id="do">
    <div class="left">
      <div class="part1-item">
        <div class="item">
          <div class="itemBlue">
            <img src="../Home/images/roc.svg" />
            ROC
          </div>
          <div class="item-right">
            1 ROC = 1.02 USD
            <router-link  to="/wallet?type=do" class="btn btn-primary">Get DO</router-link>
          </div>
        </div>
      </div>

      <!-- <div class="part2-item">
        <div class="item">
          <div class="item-number">2000</div>
          <div class="item-text">Day 1 Last month</div>
        </div>
        <div class="item">
          <div class="item-number">1,000,000</div>
          <div class="item-text">Last week</div>
        </div>
        <div class="item">
          <div class="item-number">2,000,000</div>
          <div class="item-text">Now</div>
        </div>
      </div> -->

      <div class="part3-item">
        <div class="item">
          <div class="item-number">Market Cap</div>
          <div class="item-text">2,000,000 USD</div>
        </div>
        <div class="item">
          <div class="item-number">In Staking</div>
          <div class="item-text">4,000,000 ROC</div>
        </div>
        <div class="item">
          <div class="item-number">Reserved roUSD</div>
          <div class="item-text">1,700,000</div>
        </div>
        <div class="item">
          <div class="item-number">Holders</div>
          <div class="item-text">2,000</div>
        </div>
      </div>


        
    </div>
    <div class="right">
      <div class="title">Get DO from other places</div>
      <div class="link">
        <img src="../../assets/img/image 10.svg"/>
      </div>
      <div class="link">
        <img src="../../assets/img/image 13.svg"/>
      </div>
      <div class="link">
        <img src="../../assets/img/image 14.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        // key: value
      }
    },
  }
</script>

<style lang="less" scoped>
// /deep/ body {
//   background: #000;
//   width: 100%;
//   height: calc(100% - 60px)
// }
#do {
  display: flex;
  width: 1110px;
  margin: 130px auto 60px;
  justify-content: space-between;
  align-items: flex-start;
  // background:rgba(0, 0, 0, 0.15);
  .left {
    width: 730px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    .part1-item {
      height: 70px;
      .item {
        display: flex;
        justify-content: space-between;
        .itemBlue {
          display: inline-block;
          font-family: Louis George Cafe;
          font-size: 24px;
          line-height: 28px;
          color: #085da3;
          img {
            margin-top: -6px;
          }
        }
        .item-right {
          font-family: Louis George Cafe;
          font-size: 16px;
          line-height: 140%;
          color: #4f4f4f;
          margin-top: 3px;
        }
      }
      .btn {
        width: 110px;
        height: 40px;
        padding-left: 0px;
        padding-right: 0px;
        min-width: auto;
        margin-left: 20px;
      }
    }
    .part2-item {
      display: flex;
      .item {
        flex-grow: 1;
      }
    }
    .part3-item {
      margin-top: 40px;
      display: flex;
      .item {
        flex-grow: 1;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          height: 34px;
          width: 1px;
          border-right: 1px;
          background: #E0E0E0;
          right: 30px;
          top: 8px;
        }
        .item-number {
          color: #085DA3;
          font-family: Louis George Cafe;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
        .item-text {
          font-family: Louis George Cafe;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #4F4F4F;
        }
      }   
      .item:last-child {
        &:after{
          display: none;
        }
      }   
    }
  }
  .right {
    width: 350px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    align-items: flex-start;
    .title {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 18px;
      align-items: center;
      color: #085DA3;
      margin-bottom: 14px;
    }
    .link {
      width: 290px;
      height: 86px;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      
    }
  }
}
</style>